import firebaseConfig from '../config/default';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

//firestore init
const app = initializeApp(firebaseConfig);

// utils auth
const db = getFirestore(app);
const auth = getAuth(app);

// firestore
const firestorage = getStorage(app);

// export utils 
export {
  db,
  auth,
  firestorage
}