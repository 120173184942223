import Vue from 'vue';
import { getDocs, collection, doc, getDoc, setDoc, query, orderBy, updateDoc, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { firestorage } from '../plugins/firebase';
import { db } from '../plugins/firebase';

export default {
    state: {
        articles: [],
        article: null,
    },
    mutations: {
        loadArticles(state, payload) {
            state.articles = payload;
        },
        loadArticle(state, payload) {
            state.article = payload;
        },
        addArticle(state, payload) {
            const index = state.articles.length;
            Vue.set(state.articles, index, payload);
        },
        editArticle(state, payload) {
            const art = state.articles.find(a => a.id == payload.id);
            if (art) {
                payload.article.id = payload.id;
                const index = state.articles.indexOf(art);
                Vue.set(state.articles, index, payload.article);  
            }   
        },
        deleteArticle(state, payload) {
            const articles = state.articles.filter(art => art.id != payload);
            state.articles = articles;
        }
    },
    actions: {
        async fetchArticles({commit}) {
            commit('clearError')
			commit('setLoading', true)
            const res = [];
            try {
                const q = query(collection(db, 'articles'), orderBy('date', 'desc'));
                const response = await getDocs(q);

                if (response) {
                    response.forEach(doc => {
                        const art = doc.data();
                        art.id = doc.id;
                        res.push({...art, date: art.date.toDate()});
                    })
                    commit('loadArticles', res);
                }                
                commit('setLoading', false)
            }
            catch(error) {
                console.log(error.message)
				commit('setLoading', false)
				throw error
            }
        },
        async fetchArticle({commit}, payload) {
            commit('clearError')
			commit('setLoading', true)
        
            try {
                // Create a reference to the doc.
                const docRef = doc(db, "articles", payload);
                const response = await getDoc(docRef);
                
                if (response.exists()) {
                    const art = {...response.data(), id: response.id, date: response.data().date.toDate() };
                    //console.log(art)
                    commit('loadArticle', art);
                }                
                commit('setLoading', false)
            }
            catch(error) {
                console.log(error.message)
				commit('setLoading', false)
				throw error
            }
        },
        async editArticle({commit}, payload) {
            commit('clearError')
			commit('setLoading', true)

            const id = payload.id;
        
            try {
                // Create a reference to the doc.
                const docRef = doc(db, "articles", id);
                await updateDoc(docRef, payload.article);
                               
                commit('editArticle', payload);         
                commit('setLoading', false)
            }
            catch(error) {
                commit('setError', error.message)
				commit('setLoading', false)
				throw error
            }
        },
        async addArticle({commit}, payload) {
            commit('clearError')
			commit('setLoading', true);
        
            try {
                /*upload the article image  ***** image name == article id*/
                const storageRef = ref(firestorage, `articles/${payload.id}`);
                await uploadBytes(storageRef, payload.image);
                // Get the download URL
                const url = await getDownloadURL(storageRef);

                if (url) {
                    const id = payload.id;
                    delete payload.id;

                    payload.image = url;
                    payload.date = new Date();

                    // Create a reference to the doc.
                    const docRef = doc(db, "articles", id);
                    await setDoc(docRef, payload);
                    payload.id = id
                    commit('addArticle', payload);
                }             
                commit('setLoading', false)
            }
            catch(error) {
                commit('setError', error.message)
				commit('setLoading', false)
				throw error
            }
        },
        async deleteArticle({commit}, payload) {
            commit('clearError')
			commit('setLoading', true)
        
            try {
                await deleteDoc(doc(db, "articles", payload));
                commit('deleteArticle', payload);            
                commit('setLoading', false)
            }
            catch(error) {
                commit('setError', error.message)
				commit('setLoading', false)
				throw error
            }
        },      
    },
    getters: {
        articles(state) {
            return state.articles;
        },
        article(state) {
            return state.article;
        }
    }
}