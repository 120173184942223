<template>
<!--menu-->
<v-menu bottom close-on-content-click>
  <template v-slot:activator="{ on, attrs }">
    <v-btn icon small dark
      class="mt-n3"
      color="primary"
      v-bind="attrs" v-on="on">
      <v-icon>mdi-cash-sync</v-icon>
    </v-btn>
  </template>
  <v-list dense>
    <v-list-item-group class="text-left" color="primary" v-model="item">
      <v-list-item v-for="(item, index) in items" :key="index"
        :class="{'active': index === 0 }"
        @click="changeCurrency(item)">
        <v-list-item-title>{{ item }}</v-list-item-title>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</v-menu>
</template>
<script>

export default {
    name: 'CurrencyMenu',
    data() {
        return {
          items: ['MDL', 'RON', 'USD'],
          item: 0,
        }
    },
    computed: {
      currency() {
        return this.$store.getters.currency;
      }
    },
    methods: {
      changeCurrency(item) {
        this.$store.dispatch('setCurrency', item);
      },
    },
    mounted() {
      if (this.currency) {
        this.item = this.items.indexOf(this.currency);
      }
    }
}
</script>

