import Vue from 'vue'
import Vuex from 'vuex'

import user from './user'
import auth from './auth'
import shared from './shared'
import products from './products'
import cart from './cart'
import orders from './orders'
import articles from './articles'
import file from './file'
import reviews from './reviews'
import action from './actions'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		user, shared, products, cart, orders, articles,
    file, reviews, auth, action
	}
})
