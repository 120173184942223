<template>
  <v-footer
    v-bind="localAttrs"
    padless
  >
    <v-card
      flat
      tile
      width="100%"
      class="primary text-center"
    >
      <v-card-text>
        <v-btn
          v-for="item, i in icons"
            :key="i"
            :class="[$vuetify.breakpoint.smAndDown ? 'mx-2' : 'mx-4']"
            icon
            :to="item.to"
          >
          <v-icon size="24px" color="white">
            {{ item.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-text class="white--text">
        <v-row justify="space-between">
          <v-col class="text-subtitle-1"> &copy; momykeep, {{ new Date().getFullYear() }}</v-col>
          <v-col>{{'developed by' | localize }} SC Datalinie Business Inginerie SRL</v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!--   donor's text  -->
    <v-card
      flat
      tile
      width="100%"
      class="white text-center"
    >
      <v-row class="mx-12 my-1" justify="center" no-gutters>
        <v-col cols="12" class="caption text-justify mt-2" align-self="center">
          <strong>
            {{ 'footer text' | localize }}
          </strong>
        </v-col>
        <v-col align-self="center">
          <v-img 
            :src="swedenLogo"
            :lazy-src="swedenLogo"
            contain
            class="mx-auto"
            max-width="130">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" justify="center">
                <v-progress-circular indeterminate color="secondary"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
        <v-col align-self="center">
          <v-img
            src="@/assets/logos/uk_gov.png"
            class="mx-auto"
            contain 
            max-width="245">
          </v-img>
        </v-col>
        <v-col align-self="center">
          <v-img
            src="@/assets/logos/undp.png"
            class="mx-auto"
            contain 
            max-width="45">
          </v-img>
        </v-col>
		</v-row>
    </v-card>
    
  </v-footer>
</template>
<script>
export default {
    name: 'AppFooter',
    data() {

      return {
        icons: [
        { icon: 'mdi-home', to: '/'},
        { icon: 'mdi-baby-face-outline', to: '/#safety' },
        { icon: 'mdi-cart-variant', to: '/products' },
        { icon: 'mdi-map-marker', to: '/#stores' },
        { icon: 'mdi-email', to: '/#contacts' },
        
      ],
      items: [
        'default',
        'absolute',
        'fixed',
      ],
    }
  },
    computed: {
      locale() {
        return this.$store.getters.locale;
      },
      localAttrs() {
        return {
          absolute: false,
          fixed: false
        }
      },
      swedenLogo() {
        return require(`@/assets/logos/sweden_${this.locale.split('-')[0]}.png`);
      }
    },
  }
</script>