import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import vuetify from './plugins/vuetify'

import { onAuthStateChanged } from "firebase/auth";
import { auth } from './plugins/firebase';

//localize filter
import localizeFilter from './filters/localize.filter'

Vue.filter('localize', localizeFilter)
Vue.config.productionTip = false

let app = '';

onAuthStateChanged(auth, async(user) => {
  
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }

  if (user && user.emailVerified) {
    await store.dispatch('setUser', user);
    await store.dispatch('fetchUserData');
  }

})
