<template>
    <section id="blog"
        class="grey lighten-3">
        <div class="py-8"></div>

        <v-container class="text-center">

          <v-theme-provider dark>
            <h3 class="display-1 font-weight-semibold primary--text mb-3">{{'blog' | localize }}</h3>
          </v-theme-provider>
          
          <v-responsive
            class="mx-auto mb-8"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-row justify="center">
            <v-col
              v-for="article in paginatedArticles"
              :key="article.id"
              cols="12"
              md="4"
              sm="6"
            >
              <v-card
                class="pa-4"
                flat
              >
                <v-theme-provider dark>
                  <v-img v-if="article.image"
                    class="rounded"
                    :src="article.image"
                    :lazy-src="article.image"
                    aspect-ratio="3/2"
                   >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        justify="center"
                      >
                      <v-progress-circular
                        indeterminate
                        color="secondary"
                      ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-theme-provider>

                <v-card-title
                  class="subtitle-1 primary--text justify-center font-weight-bold">
                  {{ article[`title_${locale.split('-')[0]}`] }}
                </v-card-title>

                <v-card-text
                  class="subtitle-1 text-justify">
                {{ shortText(article[`text_${locale.split('-')[0]}`])}}...
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    class="mx-auto text-lowercase"
                    color="primary"
                    :href="`/article/${article.id}`"
                    outlined>
                    {{'continue reading' | localize }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <!-- pagination -->
          <div class="text-center mt-12">
            <v-pagination v-model="page" 
              :length="pages">
            </v-pagination>
          </div>
        </v-container>

        <div class="py-8"></div>
    </section>
</template>
<script>
export default {
    data() {
        return {
          length: 118,
          page: 1,
        }
    },
    computed: {
      articles() {
        return this.$store.getters.articles;
      },
      locale() {
        return this.$store.getters.locale;
      },
      startIndex() {
        return this.NUM_OF_ARTICLES * (this.page - 1);
      },
      endIndex() {
        return this.NUM_OF_ARTICLES * this.page;
      },
      pages() {
        const nums = this.articles?.length;
        const pages = Math.ceil(nums / this.NUM_OF_ARTICLES);
        return pages > 0 ? pages : 1;
      },
      paginatedArticles() {
        return this.articles?.slice(this.startIndex, this.endIndex);
      },
      NUM_OF_ARTICLES() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 3;
      }
      if (this.$vuetify.breakpoint.smOnly) {
        return 2;
      }
      if (this.$vuetify.breakpoint.xsOnly) {
        return 1;
      }
      return 3;
    }
    },
    methods: {
      shortText(text) {
        return text.substring(0, this.length)
      }
    },
    async mounted() {
      await this.$store.dispatch('fetchArticles');
    }
}
</script>