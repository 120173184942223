//import Vue from 'vue';
import { 
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendEmailVerification,
    sendPasswordResetEmail,
    updateProfile, signOut } from "firebase/auth";
import { auth } from '../plugins/firebase';

import { setDoc, doc } from 'firebase/firestore';
import { db } from '../plugins/firebase';

export default {
    state: {
        user: null,
        loggedIn: false,
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload;
            if (state.user !== null) {
                state.loggedIn = true;
            }
            else {
                state.loggedIn = false;
            }  
        },
    },
    actions: {
        async registration({commit, getters}, payload) {
            commit('clearError')
			commit('setLoading', true)

            try {
                await createUserWithEmailAndPassword(auth, payload.email, payload.password)
                if (auth.currentUser) {
                    //add profile in userData collection
                    delete payload.password;
                    payload.role = 'USER';
                    await setDoc(doc(db, "userData", auth.currentUser.uid), payload);
                    
                    let displayName = '';
                    payload.firstName  
                    ? displayName = `${payload.firstName} ${payload.lastName}`
                    : displayName = payload.lastName;
                    
                    //update profile
                    await updateProfile(auth.currentUser, {
                        displayName: displayName,
                        phoneNumber: payload.phone,
                    }); 
                    
                    //send to email
                    auth.languageCode = getters.locale.split('-')[0];//language
                    await sendEmailVerification(auth.currentUser);
                    commit('setError', 'Registration success. On your email was sent an activation link');
                }                
                commit('setLoading', false)
            } 
            catch(error) {
                console.log(error.code)
                let txt = 'authentication error';
                switch(error.code) { 
                    case 'auth/email-already-in-use':
                        txt = 'this email used already'
                        break;
                    case 'auth/wrong-password':
                        txt = 'password not matched'
                        break;
                    case 'auth/invalid-email':
                        txt = 'email not matched'
                        break;
                }
                commit('setError', txt)
				commit('setLoading', false)
            }
        },
        async login({commit}, payload) {
            commit('clearError')
			commit('setLoading', true)
            
            try {
                await signInWithEmailAndPassword(auth, payload.email, payload.password)
                const user = auth.currentUser
                const emailVerified = user.emailVerified
                
                if (user && emailVerified) {
                    commit('setUser', auth.currentUser);
                }
                else {
                   commit('setError', 'Please, activate your account by activation link in your email'); 
                }               
                commit('setLoading', false)
            }
            catch(error) {
                console.log(error.code)
                let txt = 'authentication error';
                switch(error.code) { 
                    case 'auth/too-many-requests':
                        txt = 'too many requests to sign in to the account'
                        break;
                    case 'auth/wrong-password':
                        txt = 'password not matched'
                        break;
                    case 'auth/user-not-found':
                        txt = 'user not found'
                        break;
                }
                commit('setError', txt)
				commit('setLoading', false)
            }
        },
        async logout({commit}) {
            commit('clearError');
            commit('setLoading', true)
            try {
               await signOut(auth);
                commit('setUser', null);
                commit('setLoading', false); 
            }
            catch(error) {
                commit('setError', error.message)
				commit('setLoading', false)
				throw error  
            }
            
        },
        setUser({commit}, payload) {
            commit('clearError')
            commit('setUser', payload)  
        },
        async resetPassword({commit, getters}, payload) {
            commit('clearError');
            commit('setLoading', true)
            try {
                auth.languageCode = getters.locale.split('-')[0];//language
                await sendPasswordResetEmail(auth, payload)
                commit('setError', 'Password reset email sent!');
                commit('setLoading', false); 
            }
            catch(error) {
                commit('setError', error.message)
				commit('setLoading', false)
				throw error  
            }
        },
        
    },
    getters: {
        user (state) {
            return state.user;
        },
        loggedIn (state) {
            return state.loggedIn;
        },
    }
}