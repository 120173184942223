<template>
    <!--menu-->
    <v-menu bottom close-on-content-click>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon small dark
          class="mt-n3"
          color="primary"
          v-bind="attrs" v-on="on">
          <v-icon>mdi-web</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item-group class="text-left"
          v-model="active"
          mandatory
          color="primary">
          <v-list-item v-for="(item, index) in locales"
            :key="index"
            @click="changeLocale(item.value)">
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
</template>
<script>

export default {
  name: 'LocalesMenu',
  data() {
    return {
      active: 0,
      locales: [
        { text: 'English', value: 'en-US' },
        { text: 'Romănă', value: 'ro-RO' },
        { text: 'Русский', value: 'ru-RU' }
      ]
    }
  },
  computed: {
    locale() {
      return this.$store.getters.locale;
    },
  },
  watch: {
    locale() {
      localStorage.setItem('locale', JSON.stringify(this.locale));
    }
  },
  methods: {
    async changeLocale(locale) {
      await this.$store.dispatch('setLocale', locale);
    },
    isActive(item) {
      return Boolean(item === this.active);
    }
  },
  mounted() {
    if (this.locale) {
      this.active = this.locales.findIndex(i => i.value === this.locale);
    }
  }
}
</script>
