const _locale = JSON.parse(localStorage.getItem('locale'));
const _currency = JSON.parse(localStorage.getItem('currency'));

export default {
	state: {
		error: null,
		loading: false,
		locale: _locale ? _locale : 'ro-RO',
		currency: _currency ? _currency : 'MDL',
		snackbar: { on: false, color: "white", text: "", timeout: 3000 }
	}, 
	mutations: {
		setLoading (state, payload) {
			state.loading = payload;
		},
		setLocale (state, payload) {
			state.locale = payload;
		},
		setCurrency (state, payload) {
			state.currency = payload;
		},
		setError(state, payload) {
			state.error = payload;
		},		
		clearError(state) {
			state.error = null;
		},
		setSnackbar(state, payload) {
			state.snackbar = payload;
		}		
	},
	actions: {
		setLocale({commit}, payload) {
			commit('clearError')
			commit('setLocale', payload)
		},
		setCurrency({commit}, payload) {
			commit('clearError')
			commit('setCurrency', payload)
		},
		setLoading ({commit}, payload) {
			commit('setLoading', payload)
		},
		setError ({commit}, payload) {
			commit('setError', payload)
		},
		clearError ({commit}) {
			commit('clearError')
		},
		showSnackbar({commit}, payload) {
			commit('clearError')
			commit('setSnackbar', payload)
		}
	},
	getters: {
		loading(state) {
			return state.loading;
		},
		error(state) {
			return state.error;
		},
		locale(state) {
			return state.locale;
		},
		currency(state) {
			return state.currency;
		},
		snackbar(state) {
			return state.snackbar;
		}
	}
}