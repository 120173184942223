<template>
    <section id="reviews"
        class="accent darken-1">
        <div :class="[$vuetify.breakpoint.smAndDown ? 'py-2' : 'py-8']"></div>

        <v-container class="text-center">

          <v-theme-provider dark>
            <h3 class="display-1 font-weight-semibold primary--text mb-3">{{'reviews' | localize }}</h3>
          </v-theme-provider>
          
          <v-responsive
            class="mx-auto"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

            <v-carousel class="pa-0"
              hide-delimiters
              show-arrows
            >
              <v-carousel-item
                v-for="(slide, i) in slides"
                :key="i"
              >
                <v-row class="d-flex fill-height justify-start align-center">
                  <v-col v-for="review in paginatedReviews(slide)"
                    :key="review.id"
                    cols="12" md="4" sm="6">

                    <v-card flat class="pa-2"
                      min-height="335">

                      <v-theme-provider dark>
                        <v-avatar
                          class="elevation-3 mb-1"
                          size="80">
                          <v-img :src="review.photo"></v-img> 
                        </v-avatar>
                      </v-theme-provider>
                
                      <v-card-title
                        class="justify-center font-weight-semiblack">
                        {{ review.author }}

                        <v-btn v-if="review.facebookUrl" icon small
                          class="ml-2"
                          color="primary"
                          target="_blank"
                          :href="review.facebookUrl">
                          <v-icon>mdi-facebook</v-icon>
                        </v-btn>
                      </v-card-title>
                
                      <div class="caption pl-4">{{ 'date' | localize }}: {{ dateLocale(review.date) }}</div>

                      <v-card-text 
                        class="body-2 text-justify">
                        {{ review[`text_${locale.split('-')[0]}`] }}
                      </v-card-text>
                      
                      <div class="text-center">
                        <v-rating
                          v-model="review.rating"
                          background-color="primary"
                          color="primary"
                          readonly
                          half-increments
                          small
                        ></v-rating>
                      </div>
                    </v-card>

                  </v-col>
                </v-row>
              </v-carousel-item>
            </v-carousel>
        </v-container>
        <div :class="[$vuetify.breakpoint.smAndDown ? 'py-2' : 'py-4']"></div>
    </section>
</template>
<script>
export default {

  data() {
    return { 
      model: 0,
      slide: 1,
    }
  },
  computed: {
    reviews() {
      return this.$store.getters.reviews;
    },
    locale() {
      return this.$store.getters.locale;
    },
    slides() {
      const nums = this.reviews?.length;
      const pages = Math.ceil(nums / this.NUM_OF_REVIEWS);
      return pages > 0 ? pages : 1;
    },
    NUM_OF_REVIEWS() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 3;
      }
      if (this.$vuetify.breakpoint.smOnly) {
        return 2;
      }
      if (this.$vuetify.breakpoint.xsOnly) {
        return 1;
      }
      return 3;
    }
   
  },
  methods: {
    paginatedReviews(slide) {
      const startIndex = this.NUM_OF_REVIEWS * (slide - 1);
      const endIndex = this.NUM_OF_REVIEWS * slide;
      return this.reviews?.slice(startIndex, endIndex);
    },
    dateLocale(date) {
      if (date) {
        return new Date(date).toLocaleDateString(this.locale);
      }
      return 'no date'
    },
  },
  async mounted() {
    await this.$store.dispatch('fetchReviews');
  }
}
</script>