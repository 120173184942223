<template>
    <!--dialog-->
    <v-dialog
        v-model="dialog"
        max-width="700"
    >
    <v-container class="pa-0">
        <v-row v-if="!loading && action" no-gutters>
            <v-col cols="12" sm="6" class="hidden-xs-only">
                <v-img 
                    :src="action.image"
                    :lazy-src="action.image"
                    max-width="400"
                    :aspect-ratio="0.66"
                    class="mx-auto bg-white">
                    
                    <template v-slot:placeholder>
                        <div class="d-flex align-center justify-center fill-height">
                            <v-progress-circular indeterminate color="secondary"
                            ></v-progress-circular>
                        </div>
                    </template>
                </v-img>
            </v-col>
            <v-col cols="12" sm="6">
                <v-card tile outlined
                    class="fill-height"
                > 
                <v-container class="fill-height justify-center">
                <div>
                    <div >
                        <v-img 
                            :src="require('@/assets/logo.png')" 
                            max-width="180" 
                            contain
                            class="mx-auto">
                        </v-img>
                    </div>
                    <v-card-title class="pa-2 text-h5 font-weight-bold justify-center text-center
                        primary--text">
                        {{ action[`title_${locale.split('-')[0]}`] }} 
                    </v-card-title>
                    
                    <v-card-text class="text-center font-weight-bold mb-4">

                        <div class="text-h5 justify-center primary--text">
                            {{ action[`text_${locale.split('-')[0]}`] }}
                        </div> 
                        
                        <div class="text-h5 justify-center primary--text">
                            {{'discount' | localize}} {{ action.discount }}%
                        </div> 
                        
                        <div class="mt-2 body-2">
                            {{ 'from' | localize }} {{ dateLocale(action.date_start) }} 
                            {{ 'to' | localize }} {{ dateLocale(action.date_end) }}
                        </div>
                    </v-card-text>

                    <v-card-actions>
                        <div class="mx-auto">
                            <v-btn
                                color="primary"
                                outlined
                                class="text-lowercase mr-2"
                                @click="toOnlineStore()"
                            >
                                {{'buy online' | localize }}
                            </v-btn>

                            <v-btn
                                color="#A9B7DF"
                                class="text-lowercase"
                                outlined
                                @click="closeDialog()"
                            >
                                {{'close' | localize }}
                            </v-btn> 
                        </div>
                    </v-card-actions>
                </div>
                </v-container>
                
                </v-card>
            </v-col>
        </v-row>
    </v-container> 
    </v-dialog>
</template>
<script>
export default {
    data() {
        return {

        } 
    },
    computed: {
        loading() {
            return this.$store.getters.loading;
        },
        locale() {
            return this.$store.getters.locale;
        },
        action() {
            return this.$store.getters.action;
        },
        dialog() {
            return this.$store.getters.actionDialog;
        }
    },
    methods: {
        dateLocale(date) {
            if (date) {
                return new Date(date).toLocaleDateString(this.locale);
            }
            return 'no date'
        },
        closeDialog() {
          this.$store.dispatch('setDialog', false);  
        },
        toOnlineStore() {
            this.$router.replace('/products?filter=sets');
            this.$store.dispatch('setDialog', false);
        }
    }
}
</script>