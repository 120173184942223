import Vue from 'vue'

import { setDoc, doc, getDoc, getDocs, collection, updateDoc } from 'firebase/firestore';
import { db } from '../plugins/firebase';

export default {
    state: {
        userData: null,
        users: [],
    },
    mutations: {
        setUserData(state, payload) {
            state.userData = payload;
        },
        loadUsers(state, payload) {
            state.users = payload;
        },
        editUser(state, payload) {
            let user = state.users.find(u => u.id == payload.id);
            const index = state.users.indexOf(user);
            Vue.set(state.users, index, { ...user, ...payload });
        }
    },
    actions: {
        async fetchUserData({commit, getters}) {
            commit('clearError')
			commit('setLoading', true)
            
            try {
                const docRef = doc(db, 'userData', getters.user.uid);
                const docSnap = await getDoc(docRef);
                
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    data.userId = docSnap.id;
                    commit('setUserData', data);
                }           
                commit('setLoading', false); 
            }
            catch(error) {
                console.log(error.message)
				commit('setLoading', false)
				throw error
            }
        },
         async fetchAllUsers({commit}) {
            commit('clearError')
			commit('setLoading', true)
            const res = [];
            try {
                const response = await getDocs(collection(db, 'userData'))
                
                if (response) {
                    response.forEach(doc => {
                        const user= doc.data();
                        user.id = doc.id;
                        //console.log(user)
                        res.push(user);
                    })
                    
                    commit('loadUsers', res);
                }                
                commit('setLoading', false)
            }
            catch(error) {
                commit('setError', error.message)
				commit('setLoading', false)
				throw error
            }
        },
        async updateUserData({commit, getters}, payload) {
            commit('clearError')
			commit('setLoading', true)
            
            try {
                const docRef = doc(db, 'userData', getters.user.uid);
                const docSnap = await setDoc(docRef, payload, { merge: true });
                if (docSnap) {
                    const data = docSnap.data();
                    data.userId = docSnap.id;
                    commit('setUserData', data);
                }           
                commit('setLoading', false); 
            }
            catch(error) {
                console.log(error.message)
				commit('setLoading', false)
				throw error
            }
        },
        //admin only
        async editUser({commit}, payload) {
            commit('clearError')
			commit('setLoading', true)

            const userId = payload.id;
            delete payload.id;
            
            try {
                // edit a doc into collection
                const docRef = doc(db, 'userData', userId);
                await updateDoc(docRef, payload);
                
                payload.id = userId;
                commit('editUser', payload);           
                commit('setLoading', false); 
            }
            catch(error) {
                console.log(error.message)
				commit('setLoading', false)
				throw error
            }
        },
    },
    getters: {
        isAdmin (state) {
            if (state.userData?.role && state.userData.role == 'ADMIN') {
                return true;
            }
            return false;
        },
        userData(state) {
            return state.userData;
        },
        users(state) {
            return state.users;
        }
    }
}