import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/lib/services/goto'
import Home from '../views/Home.vue'
import Products from '../views/Products.vue'

import { auth } from '../plugins/firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/products',
    name: 'Products',
    component: Products
  },
  {
    path: '/product/:id',
    name: 'ProductPage',
    params: 'id',
    component: () => import('@/views/Product.vue'),
  },
  {
    path: '/article/:id',
    name: 'BlogArticle',
    params: 'id',
    component: () => import('@/views/Article.vue'),
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('@/views/SignUp.vue'),
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: () => import('@/views/SignIn.vue'),
  },
  {
    path: '/resetPass',
    name: 'ResetPassword',
    component: () => import('@/views/ResetPass.vue'),
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: () => import('@/views/Profile.vue'),
    meta: {authRequired: true}
  },
  {
    path: '/checkouts',
    name: 'UserContactInfo',
    component: () => import('@/views/ContactInfo.vue'),
  },
  {
    path: '/dashboard',
    name: 'AdminDashrord',
    component: () => import('@/views/Dashboard.vue'),
    meta: {authRequired: true}
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.path == '/') {
      if (to.hash) {
        scrollTo = to.hash 
      } else if (savedPosition) {
        scrollTo = savedPosition.y
      }
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(goTo(scrollTo, { behavior: 'smooth'}))
        }, 300)
      })
    }

  },
  
})

router.beforeEach(async(to, from, next) => {

  const user = auth.currentUser;

    if (to.matched.some(route => route.meta.authRequired)) { 
  
      if (user) {
        next()
      }
      else {
        next({ path: '/signin' })
      }
    }
    else {
      next()
    }
  })

export default router
