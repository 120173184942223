import Vue from 'vue';

import { getDocs, collection, addDoc, query, orderBy, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../plugins/firebase';

export default {
    state: {
        reviews: [],
    },
    mutations: {
        loadReviews(state, payload) {
            state.reviews = payload;
        },
        addReview(state, payload) {
            const idx = state.reviews.length;
            Vue.set(state.reviews, idx, payload);
        },
        deleteReview(state, payload) {
            const reviews = state.reviews.filter(r => r.id !== payload);
            state.reviews = reviews;
        },
    },
    actions: {
        async fetchReviews({commit}) {
            commit('clearError')
			commit('setLoading', true)
            const res = [];
            try {
                
                const q = query(collection(db, 'reviews'), orderBy('date', 'desc'))
                const response = await getDocs(q)

                if (response) {
                    response.forEach(doc => {
                        const view = doc.data();
                        view.id = doc.id;
                        res.push({...view, date: view.date.toDate()});
                    })
                    commit('loadReviews', res);
                }                
                commit('setLoading', false)
            }
            catch(error) {
                console.log(error.message)
				commit('setLoading', false)
				throw error
            }
        },
        async addReview({commit}, payload) {
            commit('clearError')
			commit('setLoading', true)
            //add date to order
            payload.date = new Date();
                
            try { 
                // add a doc into collection
                const docRef = await addDoc(collection(db, "reviews"), payload);
    
                if (docRef) {
                    payload.id = docRef.id;
                    commit('addReview', payload);
                }                
                commit('setLoading', false)
            }
            catch(error) {
                commit('setError', error.message)
				commit('setLoading', false)
				throw error
            }
        },
        async deleteReview({commit}, payload) {
            commit('clearError')
			commit('setLoading', true)
                
            try { 
                // delete a doc from the collection
                await deleteDoc(doc(db, "reviews", payload));
                commit('deleteReview', payload);               
                commit('setLoading', false)
            }
            catch(error) {
                commit('setError', error.message)
				commit('setLoading', false)
				throw error
            }
        },
    },
    getters: {
        reviews(state) {
            return state.reviews;
        }
    }
}