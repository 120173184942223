<template>
  <v-container class="pa-0" fluid >
    <!--discount dialog-->
    <discount-dialog :dialog="dialog"></discount-dialog>
    <!---home--->
    <section id="home" color="accent">
      <v-row no-gutters>
        <v-img :max-height="'calc(100vh - ' + $vuetify.application.top + 'px)'" 
          :src="$vuetify.breakpoint.xsOnly ? require('../assets/hello.jpg') : require('../assets/main.jpg')"
          :lazy-src="$vuetify.breakpoint.xsOnly ? require('../assets/hello.jpg') : require('../assets/main.jpg')">
          <v-theme-provider light>
            <v-container fill-height>
              <v-row 
                :class="[$vuetify.breakpoint.mdAndDown ? 'ml-4' : 'ml-n10']"
                justify="start">
                <v-col 
                  class="primary--text text-left down" 
                  :cols="$vuetify.breakpoint.smAndDown ? 12 : 3" tag="h3">
                  <span 
                    :class="[$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title']" 
                    class="font-weight-bold">
                    {{ 'home-text' | localize }}
                  </span>
                  <router-link :to="{ path: '/', hash: 'products' }" class="text-decoration-none">
                    <div class="mx-auto">
                      <v-btn class="mt-2 mx-auto" color="primary" fab outlined>
                        <v-icon>mdi-chevron-double-down</v-icon>
                      </v-btn>
                    </div>
                  </router-link>
                </v-col>
              </v-row>
            </v-container>
          </v-theme-provider>
        </v-img>
      </v-row>
    </section>
    <!---products--->
    <section id="products" class="grey lighten-3">
      <div class="py-12"></div>

      <v-container class="text-center">

        <v-theme-provider dark>
          <h3 class="display-1 font-weight-semibold primary--text mb-3">
            {{'our products' | localize}}
          </h3>
        </v-theme-provider>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-row justify="center">
          <v-col v-for="(item, i) in products" :key="i" cols="12" md="4" sm="6">
            <v-theme-provider light>
              <v-card color="secondary" class="pa-1" flat>
                <div>
                  <v-img class="rounded ma-1"
                    :src="require('@/assets/products/' + item.src)"
                    :lazy-src="require('@/assets/products/' + item.src)"
                    aspect-ratio="3/2">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" justify="center">
                        <v-progress-circular indeterminate color="secondary">

                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
                <v-card-title class="justify-center title primary--text">
                  {{ `${item.title}` | localize }}
                </v-card-title>

                <v-card-text class="subtitle-1 text-justify">
                  {{ localeText(item) }}
                </v-card-text>
                <v-card-actions>
                  <v-btn class="mx-auto text-lowercase" 
                    color="primary" :href="`/products?filter=${item.query}`" outlined>
                    {{ 'buy now' | localize }} online
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-theme-provider>
          </v-col>
        </v-row>
      </v-container>

      <div class="py-8"></div>
    </section>
    <!---about--->
    <section id="about">
      <div class="py-8"></div>

      <v-container class="text-center">
        <h3 class="display-1 font-weight-semibold primary--text mb-3">{{'about us' | localize}}</h3>

        <v-responsive class="mx-auto mb-8" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-responsive class="mx-auto text-subtitle-1 mb-12" max-width="80vw">
          <v-row justify="center">
            <v-col cols="12" md="6">
              <v-theme-provider dark>
                  <v-img class="mx-auto rounded-lg"
                    :src="require('../assets/factory.jpg')"
                    :lazy-src="require('../assets/factory.jpg')" 
                    aspect-ratio="3/2"
                    :max-width="$vuetify.breakpoint.smAndDown ? 450 : 700">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" justify="center">
                        <v-progress-circular indeterminate color="secondary"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
              </v-theme-provider>
            </v-col>
            <v-col cols="12" md="6" class="text-justify">
              <div v-if="locale=='ru-RU'">
                <p>
                  Команда Sofdar SRL (торговая марка Momykeep) разрабатывает и производит одежду для детей с аллергией
                  на обычные ткани, а также для преждевременно рожденных детей. 
                  Мы стремися сделать максимально счастливыми и комфортными первые месяцы жизни малыша и его мамы. 
                  Наша одежда предотвращает зуд и раздражения кожи, ее конструкция позволяет регулировать 
                  движения ребёнка, легко и быстро переодевать малыша.
                </p>
                <p class="mt-2">
                  Сегодня в разработке и производстве одежды Momykeep работают более 20 сотрудников. 
                  Мы построили небольшую современную фабрику в Кишиневе, Республика Молдова, 
                  контролируем закупки тканей, разработку, конструирование и производство одежды.
                </p>
              </div>
              <div v-if="locale == 'ro-RO'">
                <p>
                  Echipa Sofdar SRL (marcă înregistrată Momykeep) proiectează și produce haine pentru copiii 
                  cu alergii la țesăturile obișnuite, precum și pentru copiii născuți prematur. 
                  Dorim să facem ca primele luni de viață să fie cât mai fericite și confortabile pentru mamă și copil. 
                  Hainele noastre previn mâncărimile și iritațiile pielii și sunt concepute pentru a regla mișcările 
                  bebelușului și pentru a face schimbarea hainelor rapidă și ușoară.
                </p>
                <p class="mt-8">
                  În prezent, Momykeep are peste 20 de angajați pentru proiectarea și producția hainelor noastre. 
                  Am construit o fabrică mică și modernă în Chișinău, Republica Moldova, 
                  controlând procurarea țesăturilor, dezvoltarea, proiectarea și producția hainelor.
                </p>
              </div>
              <div v-if="locale == 'en-US'">
                <p>
                  Sofdar SRL team (trademark Momykeep) designs and produces clothes for children with allergies 
                  to normal fabrics and for prematurely born babies. We want to make the first months of life 
                  happy and comfortable for your baby and his mother. Our clothes prevent itching and skin irritations, 
                  their design allows us to regulate the baby's movements, it is easy and quick to change the baby.
                </p>
                <p class="mt-8">
                  Today, Momykeep employs more than 20 people in the design and production of Momykeep clothing. 
                  We have built a small modern factory in Chisinau, Republic of Moldova, we control the procurement of fabrics, 
                  development, design and production of clothing.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-responsive>
      </v-container>
      <div class="py-8"></div>
    </section>
    <!---safety--->
    <section id="safety" class="secondary lighten-2">
      <div class="py-8"></div>

      <v-container class="text-center">
        <h3 class="display-1 font-weight-semibold primary--text mb-3">{{ 'baby safety' | localize }}</h3>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>
          <v-divider></v-divider>
        </v-responsive>

        <v-responsive class="mx-auto text-subtitle-1 mb-12" max-width="80vw">
          <v-row class="fill-height"
            justify="center">

            <v-col cols="12" md="6" class="text-justify">
              <div v-if="locale == 'ru-RU'">
                <p>
                  Одежда Momykeep разработана и специально производится для детей с аллергией на обычные ткани, 
                  а также для преждевременно рожденных детей.  В процессе производства мы используем только сертифицированные 
                  гипоаллергенные, натуральные материалы, которые не вызывают зуд и раздражение кожи.
                </p>
                <p class="my-4">
                  Каждое наше изделие испытывается специалистами и мамами в течении года. 
                  Также вся одежда Momykeep проходит лабораторные исследования. 
                  Мы сотрудничали с ведущими акушерами, неонатологами и врачами педиатрами.   
                </p>
              </div>
              <div v-if="locale == 'ro-RO'">
                <p>
                  Hainele Momykeep sunt concepute și realizate special pentru bebelușii cu alergii la țesături obișnuite,
                   precum și pentru cei născuți prematur.  În procesul de fabricație folosim numai materiale naturale 
                   hipoalergenice certificate, care nu provoacă mâncărimi sau iritații ale pielii.
                </p>
                <p class="my-8">
                   Fiecare îmbrăcăminte Momykeeper este testată de experți și mame timp de un an. Toate hainele Momykeep 
                   sunt, de asemenea, testate în laborator. Am colaborat cu obstetricieni, neonatologi și pediatri de renume. 
                </p>
              </div>
              <div v-if="locale == 'en-US'">
                <p>
                  Momykeep clothing is designed and made specifically for children with allergies to common fabrics 
                  and for premature babies.  We use only certified hypoallergenic, natural materials in the manufacturing 
                  process that do not cause itching or skin irritation. 
                </p>
                <p class="my-8">
                  Every Momykeeper product is tested by experts and mothers for one year. 
                  Also, all Momykeep clothing undergoes laboratory testing. We have collaborated with leading obstetricians, 
                  neonatologists and pediatricians.   
                </p>
              </div>
            </v-col>

            <v-col cols="12" md="3">
              <v-img 
                :src="require('../assets/sertificates/sertificat_1_sm.jpg')"
                :lazy-src="require('../assets/sertificates/sertificat_1_sm.jpg')"
                contain
                class="rounded-lg"
                cover>
                
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" justify="center">
                    <v-progress-circular indeterminate color="secondary"></v-progress-circular>
                  </v-row>
                </template>
                
                <div class="d-flex justify-end">
                  <image-dialog 
                    :sertificat="require('../assets/sertificates/sertificat_1.jpg')">
                  </image-dialog>
                </div>
                    
              </v-img>

            </v-col>
            <v-col cols="12" md="3">
              <v-img 
                  :src="require('../assets/sertificates/sertificat_2_sm.jpg')"
                  :lazy-src="require('../assets/sertificates/sertificat_2_sm.jpg')"
                  contain
                  class="rounded-lg">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" justify="center">
                      <v-progress-circular indeterminate color="secondary"></v-progress-circular>
                    </v-row>
                  </template>

                  <div class="d-flex justify-end">
                    <image-dialog 
                      :sertificat="require('../assets/sertificates/sertificat_2.jpg')">
                    </image-dialog>
                  </div>
                </v-img>

            </v-col>
          </v-row>
        </v-responsive>
      </v-container>
      <div class="py-8"></div>
    </section>
    <!---reviews--->
    <reviews />
    <!---blog--->
    <blog />
    <!---stores--->
    <section  class="secondary">
      <div class="py-8"></div>

      <v-container class="text-center">
        <h3 class="display-1 font-weight-semibold primary--text mb-3">{{ 'stores' | localize }}</h3>
    
        <v-responsive class="mx-auto mb-8" width="56">
          <v-divider class="mb-1"></v-divider>
          <v-divider></v-divider>
        </v-responsive>
    
        <v-responsive class="mx-auto title font-weight-light mb-8" max-width="80vw">
          <v-row id="stores" no-gutters>
            <v-col cols="12" md="3"
              class="subtitle-1 text-left">
              <div>{{ 'Kishinev, Moldova' | localize }}:</div>
              <div v-for="(store, i) in stores" :key="i" class="my-1">
                
                <strong>{{ i + 1 }}. {{'store' | localize }} {{ store.title }}</strong>
                <div>{{ store[`text_${locale.split('-')[0]}`] }}</div>
                <div>{{ store.phone }}</div>

              </div>
            </v-col>
            <v-col cols="12" md="9">
              <iframe style="border: none;" referrerpolicy="no-referrer-when-downgrade"
                loading="lazy"
                src="https://www.google.com/maps/d/embed?mid=1NXTWVx4ggcjDDQPvolP66_vBvaHs4TA&hl=ru&ehbc=2E312F" 
                width="640" 
                height="480">
              </iframe>
            </v-col>
          </v-row>
          
        </v-responsive>
      </v-container>
    </section>
    <!---contacts--->
    <v-sheet id="contacts" color="primary lighten-2" tag="section" tile>
      <div class="py-8"></div>
      <v-container>
        <h2 class="display-1 font-weight-semibold mb-3
          primary--text
          text-lowercase text-center">
          {{ 'Contact us' | localize }}
        </h2>

        <v-responsive class="mx-auto mb-8" width="56">
          <v-divider class="mb-1"></v-divider>
          <v-divider></v-divider>

        </v-responsive>

        <v-theme-provider light>
          <v-row justify="center">
            <v-col cols="12" md="5">
              <v-card class="pa-4" color="primary" flat>
                <div>
                  <v-img 
                    src="../assets/logo_light.png" 
                    lazy-src="../assets/logo_light.png" 
                    max-width="83.3" contain
                    class="mx-auto">
                  </v-img>
                </div>
                <v-card-text class="subtitle-1 white--text text-left">
                  <strong>{{ 'store' | localize}} Mi-e Dor</strong>
                  <div>{{ 'str. Alexander Pushkin' | localize}}, 50</div>
                  <div>{{ 'Kishinev, Moldova' | localize }}</div>
                  <div>+373 78 679 888</div>

                  <v-divider class="mt-4 mb-1"></v-divider>
                  <v-divider></v-divider>

                  <div class="mt-4 caption font-weight-light">
                    <p>{{'follow us' | localize}} :</p>
                    <v-btn icon small
                      class="mr-8"
                      color="white"
                      target="_blank"
                      href="https://www.facebook.com/primulsens/"
                      >
                      <v-icon>mdi-facebook</v-icon>
                    </v-btn>
                    <v-btn icon small
                      color="white"
                      target="_blank"
                      href="https://www.instagram.com/momykeep/">
                      <v-icon>mdi-instagram</v-icon>
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="5">
              <v-form v-model="valid" ref="contactsform" lazy-validation>
                <v-text-field dense
                  v-model="firstName"
                  :label="'First name' | localize"
                  :rules="[rules.required]"
                  outlined>
                </v-text-field>
                <v-text-field dense 
                  v-model="lastName" 
                  :label="'Last name' | localize"
                  :rules="[rules.required]" 
                  outlined>
                </v-text-field>
                <v-text-field dense
                v-model="email"
                  :label="'Email' | localize" 
                  outlined
                  :rules="[rules.required, rules.email]">
                </v-text-field>
      
                <v-textarea dense
                  v-model="textarea"
                  :label="'comments' | localize"
                  outlined
                  :rules="[rules.required]">
                </v-textarea>

                <v-btn 
                  color="primary"
                  class="ml-auto text-lowercase"
                  outlined
                  :loading="loading"
                  :disabled="!valid || loading"
                  @click="onSubmit()">
                    {{'confirm' | localize }}
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-theme-provider>
      </v-container>

      <div class="py-8"></div>
    </v-sheet>

    <app-footer />
    
  </v-container>
  
</template>

<script>
import emailjs from '@emailjs/browser';
import configEmailjs from '../config/emailJS';

import localizeFilter from '../filters/localize.filter';

import AppFooter from '../layouts/AppFooter.vue';
import Blog from '../components/Blog.vue';
import DiscountDialog from '../components/DiscountDialog.vue';
import Reviews from '../components/Reviews.vue';

import ImageDialog from '../components/sertificates/showImageDialog.vue';

export default {
  name: 'Home',
  data() {
    return {
      dialog: false,
      //props
      firstName: '',
      email: '',
      lastName: '',
      textarea: '',
      valid: false,
      error: '',
      rules: {
        required: value => !!value || localizeFilter('required field'),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || localizeFilter('invalid e-mail')
        }
      },
      products: [
        {
          src: 'sets.jpg',
          title: 'gift sets',
          text_ru: 'Подарочный набор для новорождённого - это отличная возможность проявить своё внимание, любовь и заботу.',
          text_ro: 'Un set de sărbători pentru bebeluși este o modalitate excelentă de a vă arăta atenția, dragostea și grija.',
          text_en: 'A gift set with clothes for a newborn baby is a great way to show Your support and heartfelt care.',
          query: 'sets'
        },
        {
          src: 'newborn.jpg',
          title: 'for newborn babies',
          text_ru: 'Одежда, изготовленная из натуральных материалов, просто необходима Вашему ребёнку в первые месяцы жизни.',
          text_ro: 'Îmbrăcămintea din materiale naturale este esențială pentru bebelușul tău în primele luni de viață.',
          text_en: 'Clothing made from natural materials is essential for your baby in the first months of life.',
          query: 'newborn'
        },
        {
          src: 'premature.jpg',
          title: 'for premature babies',
          text_ru: 'Одежда не только очень маленькая, но сконструирована именно для самых хрупких и ранимых деток.',
          text_ro: 'Hainele nu sunt doar mici, ci sunt concepute tocmai pentru cei mai fragili și vulnerabili copii.',
          text_en: 'The clothes are not only small, but designed precisely for the most fragile and vulnerable babies.',
          query: 'premature'
        },
      ],
      items: [
        { text: 'for newborns and babies born from 700 gr.', icon: '' },
        { text: 'only by natural, hypo-allergenic highest quality fabric', icon: '' },
        { text: 'with all external seams which are sewn with natural thread', icon: '' },
        { text: 'so delicated & comfortable, that you won\'t find on any shelves', icon: '' },
        { text: 'in collaboration with neonatologists and pediatricians', icon: '' },
      ],
      stores: [
        { id: 'st-1', title: 'Mi-e Dor', text_en: 'str.Alexander Pushkin, 50',
          text_ro: 'str.Alexandr Pușkin, 50',
          text_ru: 'ул.Александра Пушкина, 50',
          phone: '+373 78 679 888'},
        { id: 'st-2', title: '9 luni', text_en: 'bd.Grigore Vieru, 20',
          text_ro: 'bd.Grigore Vieru, 20',
          text_ru: 'бульвар Григоре Виеру, 20',
         phone: '+373 790 71 234' },
        { id: 'st-3', title: 'Mama Box', text_en: 'str.Andrei Doga, 29',
          text_ro: 'str.Andrei Doga, 29',
          text_ru: 'ул.Андрея Дога, 29',
          phone: '+373 697 97 960' },
        { id: 'st-4', title: 'New Kids', text_en: 'str.Armeneasca, 45a',
          text_ro: 'str.Armeneasca, 45a',
          text_ru: 'ул.Армянская, 45a',
          phone: '+373 780 10 909' },
        { id: 'st-5', title: 'Fluture (CC Jumbo)', text_en: 'db.Decebal, 23/1',
          text_ro: 'db.Decebal, 23/1',
          text_ru: 'бульвар Дечебал, 23/1',
          phone: '+373 697 57 176'}
      ],
    }
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    locale() {
      return this.$store.getters.locale;
    },
    loading() {
      return this.$store.getters.loading;
    },
    action() {
      return this.$store.getters.action;
    }
  },
  methods: {
    localeText(item) {
      return item[`text_${this.locale.split('-')[0]}`] || ''
    },
    async onSubmit() {
      const msg = {}
      
      if (this.$refs.contactsform.validate()) {
        msg.firstName = this.firstName
        msg.email = this.email
        msg.lastName = this.lastName
        msg.message = this.textarea
      
        try {
          emailjs.init(configEmailjs.userId);
          const result = await emailjs.send(configEmailjs.serviceId, configEmailjs.templateId, msg);
          
          if(result.text === 'OK') {
            this.$store.dispatch('setError', 'Thank you for your message. We will respond as soon as possible');
            this.$refs['contactsform'].reset();
          }
        }
        catch(error) {
          this.$store.dispatch('setError', 'Your message has not been delivered. Please contact us another way')
        }
      }
    }
  },
  components: { AppFooter, Blog, Reviews, DiscountDialog, ImageDialog },
  
  async mounted() {
    await this.$store.dispatch('fetchAction');

    if (this.action && this.$route.hash == '') {
      await this.$store.dispatch('setDialog', true);
    }
  }
}
</script>
<style scoped>
.down {
  padding-top: 55vh;
}
</style>

