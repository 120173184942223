import Vue from 'vue'

const cart = JSON.parse(localStorage.getItem('cart'));
const initialState = cart
  ? { productsInCart: cart, messages: cart.length }
  : { productsInCart: [], messages: 0 };

  export default {
  state: initialState, 
  mutations: {
    addPurchase(state, payload) {
        const index = state.productsInCart.length;
        Vue.set(state.productsInCart, index, payload);
    },
    updatePurchase(state, payload) {
        const index = state.productsInCart.indexOf(payload.product);
        Vue.set(state.productsInCart, index, payload.newProd);
    },
    removePurchase(state, payload) {
      state.productsInCart = state.productsInCart.filter(prod => prod.id != payload);
    },
    addMessage(state) {
			state.messages++;
		},
    clearMessage(state) {
			if (state.messages > 0) {
				state.messages--;
			}
		},
  },
  actions: {
    addProductToCart({commit}, payload) {
        commit('addPurchase', payload);
    },
    removeProductFromCart({commit}, payload) {
        commit('removePurchase', payload);
    },
    updateProduct({commit}, payload) {
      commit('updatePurchase', payload)
    },
    addMessage ({commit}) {
			commit('addMessage')
		},
    clearMessage ({commit}) {
			commit('clearMessage')
		},
  },
  getters: {
    productsInCart(state) {
        return state.productsInCart;
    },
    messages(state) {
			return state.messages;
		},
  }   
}
