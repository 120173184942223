import store from '../store'
import ruLocale from '../locales/ru.json'
import enLocale from '../locales/en.json'
import roLocale from '../locales/ro.json'

const locales = {
    'ru-RU': ruLocale,
    'en-US': enLocale,
    'ro-RO': roLocale,
}

export default function localizeFilter(key) {
    const locale = store.getters.locale || 'ro-RO'
    return locales[locale][key] || `[Localize error]: key ${key} not found`
}