import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
    icons: { iconfont: 'mdi' },
    theme: {
        themes: {
            light: {
                primary: {/*blue*/
                    base: '#64668A',
                    lighten1: '#A9B7DF',
                    lighten2: '#C1C7DA'
                },
                secondary: {/*green*/
                    base:'#B7C4C6',
                    lighten1: '#CAD7D7',
                    lighten2: '#D9E4E4'
                },
                accent: '#D7C3BF',/*pink*/
                error: '#C2185B',/*red*/ 
            },
            dark: {
                primary: '#A9B7DF',/*blue*/
                secondary: '#CAD7D7',/*green*/
                accent: '#E1D2CE',/*pink*/
                error: '#C2185B',/*red*/
            },
        },
    },
});

