<template>
    <!--dialog-->
    <v-dialog
      v-model="dialog"
      max-width="600"
    >
        <template v-slot:activator="{ on }">
            <v-btn small
                class="mt-n3"
                color="primary"
                v-on="on"
                icon>
                <v-badge
                  :content="messages" 
                  :value="messages" 
                  color="primary" 
                  overlap>
                  <v-icon>mdi-cart-variant</v-icon>
                </v-badge>
            </v-btn>
        </template>
        <v-container class="pa-0">
            <v-card flat
              min-height="450">
                <v-card-title class="title py-4 primary--text">
                  {{ 'Your Cart' | localize }}
                </v-card-title>
                <v-card-text
                  class="subtitle-2 text-center"
                >
                <div v-if="productsInCart.length">
                  <v-row v-for="prod, i in productsInCart"
                    :key="i">
                    <v-col class="hidden-sm-and-down">
                      <v-img 
                        :src="prod.images[0].url"
                        width="90"
                        contain
                      >
                      </v-img>
                    </v-col>
                    <v-col>{{ localName(prod) }}</v-col>
                    <v-col>{{ getPrice(prod).toLocaleString('en-US') }} {{ currency }}</v-col>
                    <v-col cols="2"> 
                      <v-btn small icon
                        :disabled="prod.numbers < 2"
                        @click="updateProduct(prod, 'minus')">
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                        {{ prod.numbers }}
                      <v-btn small icon
                        @click="updateProduct(prod, 'plus')">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-col> 
                    <v-col> {{ suma(prod).toLocaleString('en-US') }} {{ currency }}</v-col>
                    <v-col>
                      <v-btn icon color="primary"
                        @click="fromCart(prod)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class="subtitle-1">
                    <v-col>{{ 'Total suma' | localize }}: {{ totalInCart().toLocaleString('en-US') }} {{currency}}</v-col>
                  </v-row>
                  <v-row>
                    <v-btn class="mx-auto my-4 text-lowercase"
                      color="primary" dark 
                      @click="confirmCart()">
                      {{'check out' | localize }}
                    </v-btn>
                  </v-row>
                </div>
                <div v-else>
                  <v-row justify="center">
                    {{ 'Your cart is currently empty' | localize }}
                  </v-row>
                </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn class="mx-auto text-lowercase"
                    color="primary"
                    outlined
                    dark 
                    @click="goShoping()">
                    {{ 'continue shopping' | localize }}
                  </v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
    </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    messages() {
      return this.$store.getters.messages;
    },
    currency() {
      return this.$store.getters.currency;
    },
    productsInCart() {
      return this.$store.getters.productsInCart;
    },
    locale() {
      return this.$store.getters.locale;
    }
  },
  watch: {
    productsInCart() {
      localStorage.setItem('cart', JSON.stringify(this.productsInCart));
    }
  },
  methods: {
    localName(item) {
      return item[`name_${this.locale.split('-')[0]}`]
    },
    getPrice(item) {
        if (this.currency === 'MDL') {
          return item.price;
        }
        const key = `price_${this.currency}`;
        return item[key];
    },
    suma (prod) {
        if (this.currency === 'MDL') {
          return prod.price * prod.numbers;
        }
        return prod[`price_${this.currency}`] * prod.numbers;
    },
    totalInCart() {
        let res = 0;
        if (this.currency === 'MDL') {
          res = this.productsInCart.reduce((summ, prod) => {
            return summ + parseInt(prod.price * prod.numbers)
          }, 0)
        }
        else {
          res = this.productsInCart.reduce((summ, prod) => {
            return summ + parseInt(prod[`price_${this.currency}`] * prod.numbers)
          }, 0)
        }
        return res 
    },
    async fromCart(prod) {
      await this.$store.dispatch('removeProductFromCart', prod.id);
      await this.$store.dispatch('clearMessage');
    },
    async updateProduct(product, operation) {
        let numbers = 0;
        if (operation === 'plus') {
          numbers = product.numbers + 1
        }
        else {
          numbers = product.numbers - 1; 
        }
        const newProd = { ...product, numbers }
        await this.$store.dispatch('updateProduct', { product, newProd });
    },
    confirmCart() {
      this.dialog = false;
      this.$router.push('/checkouts');
    },
    goShoping() {
      this.dialog = false;
      if (this.$route.path !== '/products') {
        this.$router.push('/products');
      }         
    }
  }
}
</script>