import { ref, uploadBytes, getDownloadURL, deleteObject  } from "firebase/storage";
import { firestorage } from '../plugins/firebase';

export default {
    state: {
        photoUrl: null
    },
    mutations: {
        loadPhoto(state, payload) {
            state.photoUrl = payload;
        },
    },
    actions: {
        async uploadImage({commit, getters}, payload) {
            commit('clearError')
			commit('setLoading', true)

            const uid = getters.user.uid
            try {
                // Create a reference to 'userPhotos'
                const storageRef = ref(firestorage, `userPhotos/${uid}`);
                await uploadBytes(storageRef, payload);
                // Get the download URL
                const url = await getDownloadURL(storageRef);
                if (url) {
                    //console.log(url)
                    commit('loadPhoto', url);
                }            
                commit('setLoading', false)
            }
            catch(error) {
                console.log(error.message)
				commit('setLoading', false)
				throw error
            }
        },
        async deleteImage({commit}, payload) {
            commit('clearError')
			commit('setLoading', true)
            //console.log(payload)//path
            try {
                // Create a reference to the file to delete *** payload == path to file
                const docRef = ref(firestorage, payload);
                // Delete the file
                if (docRef) {
                    await deleteObject(docRef);
                }
                commit('setLoading', false);
            }
            catch(error) {
                commit('setError', error.message)
				commit('setLoading', false)
				throw error
            }
        },
    },
    getters: {
        photoUrl(state) {
            return state.photoUrl;
        }
    }
}