<template>
    <!--dialog-->
    <v-dialog v-model="dialog" scrollable max-width="900">
        <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
                <v-icon color="primary" large>
                    mdi-magnify-plus-outline
                </v-icon>
            </v-btn>
        </template>
        <v-container class="pa-0">
            <v-sheet color="white">
                <v-row justify="center" no-gutters>
                    <v-col cols="12">
                        <v-img :src="sertificat"
                            :lazy-src="sertificat"
                            max-width="100%"
                            contain
                            class="mx-auto bg-white"
                            cover>
                            <template v-slot:placeholder>
                                <div class="d-flex align-center justify-center fill-height">
                                    <v-progress-circular indeterminate color="secondary"></v-progress-circular>
                                </div>
                            </template>
                            <v-spacer></v-spacer>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on"
                                        color="error" 
                                        @click="() => dialog = false">
                                        <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ 'close' | localize }}</span>
                            </v-tooltip>
                        </v-img>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-container>
    </v-dialog>
</template>
<script>

export default {
    props: ['sertificat'],
    data() {
        return {
            dialog: false,
        }
    },
    comments: {

    }
}
</script>