<template>
    <v-snackbar v-model="snackbar.on"
        color="primary"
        centered
        multi-line
        :timeout="snackbar.timeout">
        <div class="text-center">{{ localizeText(snackbar.text) }}</div>
        <template v-slot:action="{ attrs }">
            <v-btn text
                :color="snackbar.color"
                class="text-lowercase"
                v-bind="attrs"
                @click="snackbar.on = false">
                {{'close' | localize }}
            </v-btn>
        </template>
    </v-snackbar>
</template>
<script>
import localizeFilter from '../filters/localize.filter';

export default {
    data() {
        return {}
    },
    computed: {
        snackbar() {
            return this.$store.getters.snackbar;
        }
    },
    methods: {
        localizeText(text) {
            return localizeFilter(text);
        }
    }
}
</script>