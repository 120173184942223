<template>
  <v-app>
    <!--header-->
    <app-header />
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view/>
      </transition>
      <snackbar></snackbar>
    </v-main>
  </v-app>
</template>

<script>
import AppHeader from './layouts/AppHeader.vue';
import Snackbar from './components/Snackbar.vue';

export default {
  components: { AppHeader, Snackbar },
  name: 'App',

  data: () => ({}),
  computed: {
    snackbar() {
      return this.$store.getters.snackbar;
    },
    error() {
      return this.$store.getters.error;
    }
  },
  watch: {
    error() {
      if(this.error) {
        this.$store.dispatch('showSnackbar', {
          on: true,
          color: 'white',
          text: this.error,
          timeout: 3000
        });
      }
    }
  }
};
</script>
<style>
  #app {
    font-family: 'Roboto','Raleway', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: right;
    color: #2c3e50;
    box-sizing: border-box;
    font-display: swap;
  }
  body {
    font-size: 16px;
    font-family: 'Roboto','Raleway', Helvetica, Arial, sans-serif;
  }
  h2, h3, h4, h5, h6 {
    font-family: 'Cormorant Garamond';
  }
</style>
