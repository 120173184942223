<template>
  <v-app-bar app
    color="white" 
    height="100">
    <v-container>
      <!-- small  -->
      <div class="hidden-md-and-up">
        <v-row justify="center" class="align-center">
          <v-col cols="2" class="mr-auto">
            <v-menu offset-y
              transition="scale-transition">
              <template v-slot:activator="{ on }">
                <v-app-bar-nav-icon v-on="on">
                </v-app-bar-nav-icon>     
              </template>
              <v-list dense>
                <v-list-item-group
                  v-model="selectedItem"
                >
                <v-list-item link
                  v-for="(item, index) in smallMenu"
                  :key="index">
                  <v-list-item-title class="text-justify">
                    <router-link :to="{ path: item.path, hash: item.hash}">
                      {{ `${item.title}` | localize }}
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="4">
            <router-link :to="{ path: '/' }">
              <!--logo-->
              <span v-if="$vuetify.breakpoint.xsOnly">momy keep</span>
              <div v-else>
                <v-img class="ml-auto mt-4"
                  :src="require('@/assets/logo.png')"
                  max-width="120"
                  contain>
                </v-img>
              </div> 
            </router-link>
          </v-col>
          <v-col cols="6" class="ml-auto">
            <!--cart button-->
            <span class="pl-3 mx-auto">
              <cart />
            </span>
            <!--localization-->
            <span class="pl-3 mx-auto">
              <locales-menu />
            </span>
            <!--currency-->
            <span class="pl-3 mx-auto">
              <currency-menu />
            </span>
          </v-col>
        </v-row>
      </div>
      <!-- middle & large  -->
      <div class="down hidden-sm-and-down">
        <v-row justify="center" class="align-end">
          <v-col cols="5" class="mb-3 text-left subtitle-2">
            <span v-for="(item, i) in leftMenu"
              :key="i" class="pr-2 mx-auto">
              <router-link :to="{ path: item.path, hash: item.hash }">
                {{ `${item.title}` | localize }}
              </router-link>
            </span>
          </v-col>
          <v-col cols="2" class="text-center logo">
            <router-link :to="{ path: '/' }"> 
            <!--logo-->
              <v-img class="mx-auto mt-4" :src="require('@/assets/logo.png')" contain max-height="90" max-width="200">
              </v-img>
            </router-link>
          </v-col>
          <v-col cols="5" class="mb-3 text-right subtitle-2">
            <span v-for="(item, i) in rightMenu"
              :key="i" class="pl-2 mx-auto">
                <router-link :to="{ path: item.path, hash: item.hash }">
                  {{ `${item.title}` | localize }} 
                </router-link>
            </span>
            <!--sign In button-->
            <span v-if="!loggedIn"
              class="pl-4 mx-auto">
              <v-btn color="primary" icon small class="mt-n3" to="/signin">
                <v-icon>mdi-account</v-icon>
              </v-btn>
            </span>
            <span v-else-if="isAdmin" class="pl-4 mx-auto">
                <v-btn color="primary" icon small class="mt-n3" to="/dashboard">
                  <v-icon>mdi-account-lock</v-icon>
                </v-btn>
              </span>
            <span v-else
              class="pl-4 mx-auto">
              <v-btn color="primary" icon small
                class="mt-n3"
                to="/profile">
                <v-icon >mdi-account-lock</v-icon>
              </v-btn>
            </span>
            <!--cart button-->
            <span class="pl-4 mx-auto">
              <cart />
            </span>
            <!--localization-->
            <span class="pl-4 mx-auto">
              <locales-menu />
            </span>
            <!--currency-->
            <span class="pl-4 mx-auto">
              <currency-menu />
            </span>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-app-bar> 
</template>
<script>
//import SignIn from '../components/SignIn.vue';
import Cart from '../components/Cart.vue';
import LocalesMenu from '../components/LocalesMenu.vue';
import CurrencyMenu from '../components/CurrencyMenu.vue';

export default {
    name: 'AppHeader',
    data() {
        return {
          leftMenu: [
            { title: 'about us', path: '/', hash: '#about' },
            { title: 'baby safety', path: '/', hash: '#safety' },
            { title: 'blog', path: '/', hash: '#blog' },
            { title: 'reviews', path: '/', hash: '#reviews' },
            { title: 'contacts', path: '/', hash: '#contacts' }
          ],
          rightMenu: [
            { title: 'buy online', path: '/products?filter=sets', hash: '' },
            { title: 'stores', path: '/', hash: '#stores' },
          ],
          
        }
    },
    computed: {
      loggedIn() {
        return this.$store.getters.loggedIn;
      },
      isAdmin() {
        return this.$store.getters.isAdmin;
      },
      smallMenu () {
        const menu = this.leftMenu.concat(this.rightMenu);
        menu.push({
          title: 'Sign In', path: '/signin', hash: ''
        })
        return menu;
      },
      selectedItem: {
        get () {
        const item = this.smallMenu.find(it => it.hash === this.$route.hash && it.path === this.$route.path)
        return this.smallMenu.indexOf(item) || 0
        },
        set (val) {
          return val
        }
      },
      locale() {
        return this.$store.getters.locale;
      } 
    },
    methods: {
      link(str) {
        return str.split(' ', 1);
      },
    }, 
    components: { Cart, LocalesMenu, CurrencyMenu }
}
</script>
<style scoped>
  a, a:link, a:visited, a:hover {
    text-decoration: none;
  }
  a:hover {
    color: #A9B7DF;
  }
  .logo {
    font-family: 'Raleway';/*Cormorant Garamond*/
    font-size: 1.5em;
  }
</style>