<template>
  <v-container pa-0 fluid>
    <section class="grey lighten-3">
     <div class="py-2"></div> 

        <v-container class="text-center">
          <v-theme-provider dark>
            <h2 class="display-1 font-weight-semibold mb-3 secondary--text">
              {{'our products' | localize}}
            </h2>
          </v-theme-provider>
          
          <v-responsive
            class="mx-auto mb-12"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-row>
            <v-col class="hidden-xs-only" cols="12"
            >
              <v-btn-toggle v-model="selected"
                divided>
                <v-btn small outlined color="primary"
                  class="text-lowercase"
                  value="sets">
                  <v-icon
                    color="primary"
                    class="mr-2"
                    >mdi-gift</v-icon>
                  {{'gift sets' | localize }}
                </v-btn>
                <v-btn small outlined 
                  color="primary"
                  class="text-lowercase"
                  value="newborn">
                  <v-icon
                    class="mr-2"
                    color="primary">
                    mdi-baby-face
                  </v-icon>
                  {{'for newborn babies' | localize }}
                </v-btn>
                <v-btn small outlined 
                  color="primary"
                  class="text-lowercase"
                  value="premature">
                  <v-icon 
                    color="primary">
                    mdi-baby-carriage
                  </v-icon>
                  {{'for premature babies' | localize }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col class="hidden-sm-and-up" cols="12">
              <v-btn-toggle 
                v-model="selected"
                divided>
                <v-btn icon value="sets">
                  <v-icon large color="primary">mdi-gift</v-icon>
                </v-btn>
                <v-btn icon value="newborn">
                  <v-icon large color="primary">mdi-baby-face</v-icon>
                </v-btn>
                <v-btn icon value="premature">
                  <v-icon large color="primary">mdi-baby-carriage</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>

          <v-row v-if="products.length && !loading" justify="center">
            <v-col
              v-for="item in paginatedProducts"
              :key="item.id"
              cols="12"
              md="3"
              sm="6"
            >
              <v-card flat>
                <router-link :to="{ name: 'ProductPage', params: { id: item.id }}">
                  <v-theme-provider dark>
                    <div class="secondary">
                      <v-hover
                        v-slot="{ hover }"
                        open-delay="200"
                        close-delay="200">
                        <v-img
                          :src="hover ? getSecondImage(item) : getFirstImage(item)"
                          :lazy-src="getSecondImage(item)"
                          contain>
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-hover> 
                    </div>
                  </v-theme-provider>
                  <v-card-title
                    class="justify-center subtitle-1 py-1">
                      {{ localName(item) }}
                  </v-card-title>
                  <v-responsive
                    class="mx-auto mb-1"
                    width="56"
                  >
                    <v-divider class="mb-1"></v-divider>
                    <v-divider></v-divider>
                  </v-responsive>
                  <v-card-text
                    class="subtitle-2 text-left px-4">
                    <div>
                      {{ 'price' | localize }}:
                      <span v-if="item.oldPrice && currency === 'MDL'" class="grey--text text-decoration-line-through mr-6">{{item.oldPrice}} {{ currency }}</span>
                      <span> {{ getPrice(item).toLocaleString('en-US') }} {{ currency }}</span>
                    </div>  
                    <div>
                      {{'colors' | localize }}:
                      <span v-for="color, idx in item.colors" :key="idx">
                        <v-icon v-if="color =='white'">mdi-circle-outline</v-icon>
                        <v-icon v-else :color="color">mdi-circle</v-icon>
                      </span>                   
                    </div>
                  </v-card-text>
                </router-link>
                <v-card-actions>
                  <v-btn small
                    class="mx-auto my-1 text-lowercase"
                    color="primary"
                    outlined
                    @click="buyQuick(item)">
                    {{'buy now' | localize }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <div v-else>
            <loader />
          </div>
          <!-- pagination -->
          <div class="text-center mt-12">
            <v-pagination v-model="page" 
              :length="pages">
            </v-pagination>
          </div>
        </v-container>
        <div class="py-8"></div>
    </section>
    <app-footer />
  </v-container>
</template>

<script>

import AppFooter from '../layouts/AppFooter.vue';
import Loader from '../layouts/Loader.vue';

export default {
  name: 'Products',
  data() {
    return {
      selected: 'sets',
      NUM_OF_PRODUCTS: 8,
      page: 1,
    }
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    locale() {
      return this.$store.getters.locale;
    },
    currency() {
      return this.$store.getters.currency;
    },
    products() {
      return this.$store.getters.products;
    },
    filteredProducts() {
      if(this.selected) {
        return this.products.filter((prod) => prod.category.includes(this.selected))
      }
      return this.products;
    },
    productsInCart() {
      return this.$store.getters.productsInCart;
    },
    startIndex() {
      return this.NUM_OF_PRODUCTS * (this.page - 1);
    },
    endIndex() {
      return this.NUM_OF_PRODUCTS * this.page;
    },
    pages() {
      const nums = this.filteredProducts?.length;
      const pages = Math.ceil(nums / this.NUM_OF_PRODUCTS);
      return pages > 0 ? pages : 1;
    },
    paginatedProducts() {
      return this.filteredProducts?.slice(this.startIndex, this.endIndex);
    },
  },
  watch: {
    paginatedProducts() {
      if (this.paginatedProducts.length === 0 && this.page > 1) {
        this.page -= 1;
      }
    },
  },
  methods: { 
    isEmpty(obj) {
      for (var x in obj) {
        return false;
      }
      return true;
    },
    getFirstImage(item) {
      if (!item.images || item.images.length == 0) {
        return 'https://firebasestorage.googleapis.com/v0/b/momykeep-f0708.appspot.com/o/products%2Fnot_found.png?alt=media&token=55870c07-b1b7-4e56-b1d9-e1145d1c9fa3'
      }
      return item.images[0].url
    },
    getSecondImage(item) {
      if (!item.images || item.images.length == 0) {
        return 'https://firebasestorage.googleapis.com/v0/b/momykeep-f0708.appspot.com/o/products%2Fnot_found.png?alt=media&token=55870c07-b1b7-4e56-b1d9-e1145d1c9fa3'
      }
      else if(item.images.length < 2) {
        return item.images[0].url
      }
      return item.images[1].url
    },
    localName(item) {
      return item[`name_${this.locale.split('-')[0]}`]
    },
    getPrice(item) {
      if (this.currency === 'MDL') {
        return item.price;
      }
      const key=`price_${this.currency}`;
      return item[key];
    },
    async buyQuick(product) {

      const inCart = this.productsInCart.find(prod => prod.id === product.id)

      if (inCart) {
        //update Product quantity in cart
        inCart.numbers += 1;

        try {
          await this.$store.dispatch('updateProduct', inCart);
          //message to user
          this.$store.dispatch('showSnackbar', {
            on: true,
            color: 'white',
            text: 'added to your cart',
            timeout: 1500
          });
        }
        catch (error) {
          await this.$store.dispatch('setError', error.message)
        }

      }
      else {
        this.$store.dispatch('addMessage');
        const prod = product;
        prod.numbers = 1;
        await this.$store.dispatch('addProductToCart', prod);
        //message to user
        this.$store.dispatch('showSnackbar', {
          on: true,
          color: 'white',
          text: 'added to your cart',
          timeout: 1500
        });
      }

    }
  },
  async beforeCreate() {
    await this.$store.dispatch('fetchProducts');
    const query = this.$route.query;
    
    if (!this.isEmpty(query)) {
      this.selected = query.filter;
    }
    
  },
  components: { AppFooter, Loader }
}
</script>
<style scoped>
  a, a:link, a:visited, a:hover {
    text-decoration: none;
  }
</style>