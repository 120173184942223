import Vue from 'vue';
import { getDocs, collection, addDoc, where, query, Timestamp, orderBy, updateDoc, doc  } from 'firebase/firestore';
import { db } from '../plugins/firebase';

export default {
    state: {
        orders: [],
        userOrders: [],
    },
    mutations: {
        loadOrders(state, payload) {
            state.orders = payload;
        },
        loadUserOrders(state, payload) {
            state.userOrders = payload;
        },
        addOrder(state, payload) {
            const index = state.orders.length;
            Vue.set(state.orders, index, payload);
        },
        editOrder(state, payload) {
            let order = state.orders.find(ord => ord.id == payload.id);
            const index = state.orders.indexOf(order);
            Vue.set(state.orders, index, { ...order, ...payload });
        }
    },
    actions: {
        async fetchOrders({commit}) {
            commit('clearError')
			commit('setLoading', true)
            const res = [];
            try {
                const q = query(collection(db, 'orders'), orderBy('orderDate', 'desc'))
                const response = await getDocs(q)
                
                if (response) {
                    response.forEach(doc => {
                        const ord = doc.data();
                        ord.id = doc.id;
                        //convert from timestam to date
                        res.push({ ...ord, orderDate: ord.orderDate.toDate() });
                    })
                    
                    commit('loadOrders', res);
                }                
                commit('setLoading', false)
            }
            catch(error) {
                commit('setError', error.message)
				commit('setLoading', false)
				throw error
            }
        },
        async fetchUserOrders({commit, getters}) {
            commit('clearError')
			commit('setLoading', true)
            const res = [];
            const userId = getters.user.uid;
            try {
                
                const q = query(collection(db, "orders"),
                    where("userId", "==", userId));
                
                    const response = await getDocs(q)
                
                if (response) {
                    response.forEach(doc => {
                        const ord = doc.data();
                        ord.id = doc.id;
                        //convert from timestam to date
                        res.push({ ...ord, orderDate: ord.orderDate.toDate() });
                    })
                    
                    commit('loadUserOrders', res);
                }                
                commit('setLoading', false)
            }
            catch(error) {
                commit('setError', error.message)
				commit('setLoading', false)
				throw error
            }
        },
        async addOrder({commit}, payload) {
            commit('clearError')
			commit('setLoading', true)

            //add date to order
            const date = Timestamp.fromDate(new Date());
            payload.orderDate = date;
            try {
                // add a doc into collection
                const docRef = await addDoc(collection(db, "orders"), payload);
                if (docRef) {
                    payload.id = docRef.id;
                    commit('addOrder', payload);
                }                
                commit('setLoading', false)
            }
            catch(error) {
                commit('setError', error.message)
				commit('setLoading', false)
				throw error
            }
        },
        async editOrder({commit}, payload) {
            commit('clearError')
			commit('setLoading', true)

            const orderId = payload.id;
            delete payload.id;

            try {
                // edit a doc into collection
                const docRef = doc(db, 'orders', orderId);
                await updateDoc(docRef, payload);
                
                payload.id = orderId;
                commit('editOrder', payload);               
                commit('setLoading', false); 
            }
            catch(error) {
                commit('setError', error.message)
				commit('setLoading', false)
				throw error
            }
        },
    },
    getters: {
        orders(state) {
            return state.orders;
        },
        userOrders(state) {
            return state.userOrders;
        }
    }
}