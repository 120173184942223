import Vue from 'vue';
import { getDocs, collection, doc, getDoc, setDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../plugins/firebase';

import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { firestorage } from '../plugins/firebase';

export default {
    state: {
        products: [],
        product: null,
    },
    mutations: {
        loadProducts(state, payload) {
            state.products = payload;
        },
        loadProduct(state, payload) {
            state.product = payload;
        },
        addProduct(state, payload) {
            const index = state.products.length;
            Vue.set(state.products, index, payload);
        },
        editProduct(state, payload) {
            const prod = state.products.find(p => p.id == payload.id);
            
            if (prod) {
                prod.images = payload.images;
                const index = state.products.indexOf(prod);
                Vue.set(state.products, index, prod);
            }
        },
        deleteProduct(state, payload) {
            const products = state.products.filter(prod => prod.id != payload);
            state.products = products;
        }
    },
    actions: {
        async fetchProducts({commit}) {
            commit('clearError')
			commit('setLoading', true)
            const res = [];
            try {
                const response = await getDocs(collection(db, 'products'))
                
                if (response) {
                    response.forEach(doc => {
                        const prod = doc.data();
                        prod.id = doc.id;
                        //console.log(prod)
                        res.push(prod);
                    })
                    
                    commit('loadProducts', res);
                }                
                commit('setLoading', false)
            }
            catch(error) {
                commit('setError', error.message)
				commit('setLoading', false)
				throw error
            }
        },
        async fetchProduct({commit}, payload) {
            commit('clearError')
			commit('setLoading', true)
        
            try {
                // Create a reference to the doc.
                const docRef = doc(db, "products", payload);
                const response = await getDoc(docRef);
                
                if (response.exists()) {
                    const prod = response.data();
                    prod.id = response.id;
                    //console.log(prod)
                    commit('loadProduct', prod);
                }                
                commit('setLoading', false)
            }
            catch(error) {
                commit('setError', error.message)
				commit('setLoading', false)
				throw error
            }
        },
        async addProduct({commit}, payload) {
            commit('clearError')
			commit('setLoading', true)

            const id = payload.id;
            delete payload.id
        
            try {
                // Create a reference to the doc.
                const docRef = doc(db, "products", id);
                await setDoc(docRef, payload);
                
                payload.id = id

                commit('addProduct', payload);             
                commit('setLoading', false);
            }
            catch(error) {
                commit('setError', error.message)
				commit('setLoading', false)
				throw error
            }
        },
        async editProduct({commit}, payload) {
            commit('clearError')
			commit('setLoading', true)
            
            const id = payload.id;
            delete payload.id;

            try {
                const docRef = doc(db, "products", id);
                await updateDoc(docRef, payload);

                payload.id = id;
                
                commit('editProduct', payload);              
                commit('setLoading', false)
            }
            catch(error) {
                commit('setError', error.message)
				commit('setLoading', false)
				throw error
            }
        },
        async addProductImage({commit}, payload) {
            commit('clearError')
			commit('setLoading', true)
            console.log(payload)
            const name = payload.image.name
            try {
                /*  upload the product image  */
                const storageRef = ref(firestorage, `products/${payload.product.category}/${name}`);
                await uploadBytes(storageRef, payload.image);
                // Get the download URL
                const url = await getDownloadURL(storageRef);

                if (!payload.product.images) {
                    payload.product.images = []    
                }
                
                const images = payload.product.images;
                images.push({ name, url }) 
                
                const docRef = doc(db, "products", payload.product.id);
                await updateDoc(docRef, { images: images });

                payload.product.images = images

                commit('editProduct', payload.product);             
                commit('setLoading', false);

            }
            catch(error) {
                console.log(error)
            }
        },
        async deleteProduct({commit}, payload) {
            commit('clearError')
			commit('setLoading', true)

            try {
                await deleteDoc(doc(db, "products", payload));
                
                commit('deleteProduct', payload);              
                commit('setLoading', false)
            }
            catch(error) {
                commit('setError', error.message)
				commit('setLoading', false)
				throw error
            }
        },
    },
    getters: {
        products(state) {
            return state.products;
        },
        product(state) {
            return state.product;
        }
    }
}