import { getDocs, collection, where, setDoc, query, deleteDoc, doc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { firestorage } from '../plugins/firebase';
import { db } from '../plugins/firebase';

export default {
    state: {
        action: null,
        actionDialog: false,
    },
    mutations: {
        loadAction(state, payload) {
            state.action = payload;
        },
        deleteAction(state) {
            state.action = null;
        },
        setDialog(state, payload) {
            state.actionDialog = payload;
        }
    },
    actions: {
        async fetchAction({commit}) {
            commit('clearError')
			commit('setLoading', true)

            const date = new Date()
        
            try {
                // Create a reference to the doc.
                const q = query(collection(db, "action"),
                    where("date_end", ">=", date));
                
                const response = await getDocs(q);// 1 element array
                const res = [];
                if (response) {
                    response.forEach(doc => {
                        const act = doc.data();
                        act.id = doc.id;
                        //convert from timestam to date
                        res.push({ ...act, date_start: act.date_start.toDate(),
                        date_end: act.date_end.toDate() });
                    })
                    commit('loadAction', res[0]); 
                }     
                commit('setLoading', false)
            }
            catch(error) {
				commit('setLoading', false)
				throw error
            }
        },
        async addAction({commit}, payload) {
            commit('clearError')
			commit('setLoading', true);
        
            try {
                //upload the action image  ***** image name == action
                const storageRef = ref(firestorage, `actions/action`);
                await uploadBytes(storageRef, payload.image);
               //Get the download URL
                const url = await getDownloadURL(storageRef);

                if (url) {
                    payload.image = url;

                    // Create a reference to the doc.
                    const docRef = doc(db, "action", 'action-001');
                    await setDoc(docRef, payload);
                    payload.id = 'action-001';

                    commit('loadAction', payload);
                }             
                commit('setLoading', false)
            }
            catch(error) {
                commit('setError', error.message)
				commit('setLoading', false)
				throw error
            }
        },
        async deleteAction({commit}, payload) {
            commit('clearError')
			commit('setLoading', true)
        
            try {
                await deleteDoc(doc(db, "action", payload));
                commit('deleteAction');            
                commit('setLoading', false)
            }
            catch(error) {
                commit('setError', error.message)
				commit('setLoading', false)
				throw error
            }
        },
        setDialog({commit}, payload)  {
            commit('clearError')
			commit('setDialog', payload)
        }    
    },
    getters: {
        action(state) {
            return state.action;
        },
        actionDialog(state) {
            return state.actionDialog;
        }
    }
}